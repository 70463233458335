import { axiosModified } from "@/services/base";

class ResourceServiceAPI {
	// Fetch ("list") a Resource by Namespace:
	fetchResource(namespace, params) {
		var url = `/${namespace}/`;

		return new Promise(resolve => {
			axiosModified
				.get(url, { params: params })
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}

	// Retrieve ("get") a Resource by Namespace:
	retrieveResource(namespace, uuid, params) {
		if (uuid) {
			var url = `/${namespace}/${uuid}/`;
		} else {
			return new Promise(reject => {
				reject();
			});
		}

		return new Promise(resolve => {
			axiosModified.get(url, { params: params }).then(response => {
				resolve(response);
			});
		});
	}

	// Retrieve ("get") a Related Resource by Namespace:
	retrieveRelationalResource(namespace, uuid, relation, params) {
		if (namespace && uuid && relation) {
			var url = `/${namespace}/${uuid}/${relation}/`;
		} else {
			return new Promise(reject => {
				reject();
			});
		}

		return new Promise(resolve => {
			axiosModified.get(url, { params: params }).then(response => {
				resolve(response);
			});
		});
	}

	// Create ("post") a Resource by Namespace:
	createResource(namespace, payload) {
		var url = `/${namespace}/`;

		return new Promise(resolve => {
			axiosModified.post(url, payload).then(response => {
				resolve(response);
			});
		});
	}

	// Update ("put") a Resource by Namespace:
	updateResource(namespace, uuid, payload) {
		if (uuid) {
			var url = `/${namespace}/${uuid}/`;
		} else {
			return new Promise(reject => {
				reject();
			});
		}

		return new Promise(resolve => {
			axiosModified.put(url, payload).then(response => {
				resolve(response);
			});
		});
	}

	// Destroy ("delete") a Resource by Namespace:
	destroyResource(namespace, uuid) {
		if (uuid) {
			var url = `/${namespace}/${uuid}/`;
		} else {
			return new Promise(reject => {
				reject();
			});
		}

		return new Promise(resolve => {
			axiosModified.delete(url).then(response => {
				resolve(response);
			});
		});
	}
}

export const restfulAPIService = new ResourceServiceAPI();
