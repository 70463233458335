import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const accounts = {
	namespaced: true,
	state: {
		accounts: []
	},
	mutations: {
		setAccounts(state, payload) {
			state.accounts = payload;
		},
		setAccount(state, payload) {
			if (state.accounts[payload.index])
				state.accounts[payload.index] = payload.account;
		},
		addAccount(state, payload) {
			state.accounts.push(payload);
		}
	},
	actions: {
		async getAccounts({ state }) {
			// Returns the following
			/** accounts = [
			 *     {
			 *         "uuid": "1234-5678-9000",
			 *         "firstName": "myFirstName",
			 *         "lastName": "myLastName",
			 *         "emailAddress": "name@hiyield.co.uk",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     },
			 *     {
			 *         "uuid": "1234-5678-9111",
			 *         "firstName": "myFirstName",
			 *         "lastName": "myLastName",
			 *         "emailAddress": "name@hiyield.co.uk",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     }
			 * 	]
			 **/

			return state.accounts;
		},
		async getAccountsByEmailAddress({ dispatch, state }, emailAddress) {
			// Returns the following
			/** accounts = [
			 *     {
			 *         "uuid": "1234-5678-9000",
			 *         "firstName": "myFirstName",
			 *         "lastName": "myLastName",
			 *         "emailAddress": "sameEmail@hiyield.co.uk",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     },
			 *     {
			 *         "uuid": "1234-5678-9111",
			 *         "firstName": "myFirstName",
			 *         "lastName": "myLastName",
			 *         "emailAddress": "sameEmail@hiyield.co.uk",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     }
			 * 	]
			 **/

			// Grab from State
			var accounts = state.accounts.filter(
				account => account.emailAddress == emailAddress
			);

			// If the Length is incorrect Grab from API
			if (accounts.length < 1) {
				await dispatch("fetchAccountsByEmailAddress", emailAddress);
				accounts = state.accounts.filter(
					account => account.emailAddress == emailAddress
				);
			}

			return accounts;
		},
		async getAccountById({ dispatch, state }, uuid) {
			// Returns the following
			/** account = {
			 *     "uuid": "1234-5678-9000",
			 *     "firstName": "myFirstName",
			 *     "lastName": "myLastName",
			 *     "emailAddress": "name@hiyield.co.uk",
			 *     "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *     "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *     "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *  }
			 **/

			// Grab from State
			var account = state.accounts.filter(
				account => account.uuid == uuid
			);

			// If the Account is incorrect Grab from API
			if (account.length != 1) {
				await dispatch("fetchAccountById", uuid);
				account = state.accounts.filter(
					account => account.uuid == uuid
				);
			}

			return account[0];
		},

		async updatePartialAccounts({ dispatch }, accountPartials) {
			// Expects the following:
			/** accountPartials = [
			 *     {
			 *         "uuid": "1234-5678-9000",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     },
			 *     {
			 *         "uuid": "1234-5678-9111",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     }
			 * 	]
			 **/
			// Should use a proper async foreach
			await accountPartials.forEach(async accountPartial => {
				await dispatch("updatePartialAccount", accountPartial);
			});
		},
		async updatePartialAccount({ dispatch, commit }, accountPartial) {
			// Expects the following:
			/** accountPartial = {
			 *     "uuid": "1234-5678-9000",
			 *     "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *     "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *     "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *  }
			 **/
			await Vue.prototype.$accountsAPI
				.updateAccountPartial(accountPartial)
				.then(async response => {
					// Check & Parse Response
					if (response) {
						// Get Existing Account
						let account = await dispatch(
							"getAccountById",
							accountPartial.uuid
						);

						// VueX Store Account (Merge in Partial Changes to the Normal Account Object)
						commit("setAccount", { ...account, ...accountPartial });
					}
				});
		},
		async updateAccounts({ dispatch }, accounts) {
			// Expects the following:
			/** accounts = [
			 *     {
			 *         "uuid": "1234-5678-9000",
			 *         "firstName": "myFirstName",
			 *         "lastName": "myLastName",
			 *         "emailAddress": "name@hiyield.co.uk",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     },
			 *     {
			 *         "uuid": "1234-5678-9111",
			 *         "firstName": "myFirstName",
			 *         "lastName": "myLastName",
			 *         "emailAddress": "name@hiyield.co.uk",
			 *         "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *         "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *         "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *     }
			 * 	]
			 **/
			await accounts.forEach(async account => {
				await dispatch("updateAccount", account);
			});
		},
		async updateAccount({ commit }, account) {
			// Expects the following:
			/** account = {
			 *     "uuid": "1234-5678-9111",
			 *     "firstName": "myFirstName",
			 *     "lastName": "myLastName",
			 *     "emailAddress": "name@hiyield.co.uk",
			 *     "waiverLastSigned": "2021-01-01T08:00:00+0000",
			 *     "videoReefLastWatched": "2021-01-01T08:00:00+0000",
			 *     "videoBayLastWatched": "2021-01-01T08:00:00+0000"
			 *  }
			 **/
			await Vue.prototype.$accountsAPI
				.updateAccount(account)
				.then(response => {
					// Check & Parse Response
					if (response)
						// VueX Store Account
						commit("setAccount", account);
				});
		},

		clearAccounts({ commit }) {
			commit("setAccounts", []);
		},

		async fetchAccountsByEmailAddress({ dispatch }, emailAddress) {
			await dispatch("fetchAccountsApi", { email_address: emailAddress });
		},
		async fetchAccountsByFullName({ dispatch }, fullName) {
			fullName = fullName.split(" ");
			await dispatch("fetchAccountsApi", {
				first_name: fullName[0],
				last_name: fullName[1]
			});
		},
		async fetchAccountById({ commit }, uuid) {
			await Vue.prototype.$accountsAPI
				.fetchAccount(uuid)
				.then(response => {
					// Check & Parse Response
					if (response)
						// VueX Store Response
						commit("addAccount", response);
				});
		},
		async fetchAccountsApi({ commit }, apiParams) {
			await Vue.prototype.$accountsAPI
				.fetchAccounts(apiParams)
				.then(response => {
					// Check & Parse Response
					if (response && response.length > 0)
						response.forEach(account => {
							// VueX Store Response
							commit("addAccount", account);
						});
				});
		}
	}
};

export default accounts;
