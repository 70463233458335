import { axiosModified } from "@/services/base";
import { EventEmitter } from "events";

class SurfEventsServiceAPI extends EventEmitter {
	constructor() {
		super();
		this.accessToken = "";
	}

	//method to list ALL of the surf events that can take place
	fetchSurfEvents() {
		return new Promise(resolve => {
			axiosModified
				.get(`events`, {
					headers: {}
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}
}

const surfEventsServiceAPINew = new SurfEventsServiceAPI();

surfEventsServiceAPINew.setMaxListeners(5);

export default surfEventsServiceAPINew;