import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";

import reservations from "@/store/reservations";
import accounts from "@/store/accounts";
import locations from "@/store/locations";

Vue.use(Vuex);

/*eslint no-param-reassign: ["error", { "props": false }]*/
/*eslint indent: ["error", 2]*/
/*eslint arrow-body-style: ["error", "always"]*/
/*eslint-env es6*/

// N.B. These Stores Are Modulirised, please reference: [https://vuex.vuejs.org/guide/modules.html] for details.

const surfers = {
  namespaced: true,
  state: {
    activeSurfers: [],
    activeSurfersWaivers: [],
    fetchingSurfersLoading: false,
    fetchingSurfersSuccess: false,
    fetchingSurfersWaiversLoading: false,
    fetchingSurfersWaiversSuccess: false,
    creatingSurfersCheckInLoading: false,
    creatingSurfersCheckInSuccess: false,
    fetchingSurfersWaiversTimestamp: null,
    searchSurfWaiversLoading: false,
    searchSurfWaiversResults: [],
    searchSurfWaivers: [],
    searchSurfWaiversSuccess: false,
    searchSurfWaiversActiveUser: null,
    searchSurfWaiversError: null,
    pendingCheckIn: []
  },
  getters: {
    activeSurfers: state => {
      return state.activeSurfers;
    },
    activeSurfersWaivers: state => {
      return state.activeSurfersWaivers;
    },
    getPendingCheckIn: state => {
      return state.pendingCheckIn;
    }
  },
  mutations: {
    // API Request Mutations:
    SET_ACTIVE_SURFERS(state, payload) {
      state.activeSurfers = payload;
    },
    SET_ACTIVE_SURFERS_WAIVERS(state, payload) {
      state.activeSurfersWaivers = payload;
    },
    // API Response Mutations:
    SET_FETCHING_SURFERS_SUCCESS(state, payload) {
      state.fetchingSurfersSuccess = payload;
    },
    SET_FETCHING_SURFERS_WAIVERS_SUCCESS(state, payload) {
      state.fetchingSurfersWaiversSuccess = payload;
    },
    SET_CREATING_SURFERS_CHECKIN_SUCCESS(state, payload) {
      state.creatingSurfersCheckInSuccess = payload;
    },
    // Loading Mutations:
    SET_FETCHING_SURFERS_LOADING(state, payload) {
      state.fetchingSurfersLoading = payload;
    },
    SET_FETCHING_SURFERS_WAIVERS_LOADING(state, payload) {
      state.fetchingSurfersWaiversLoading = payload;
    },
    SET_CREATING_SURFERS_CHECKIN_LOADING(state, payload) {
      state.creatingSurfersCheckInLoading = payload;
    },
    // Fetching Timestamp Mutations:
    SET_FETCHING_SURFERS_WAIVERS_TIMESTAMP(state, payload) {
      state.fetchingSurfersWaiversTimestamp = payload;
    },
    SET_SEARCH_SURFERS_WAIVERS_LOADING(state, payload) {
      state.searchSurfWaiversLoading = payload;
    },
    SET_SEARCH_SURFERS_WAIVERS(state, payload) {
      const grouped = {};

      payload.forEach(item => {
        const key = item.firstName;
        const collection = key in grouped;
        if (!collection) {
          grouped[key] = [item];
        } else {
          grouped[key].push(item);
        }
      });

      let results = [];
      for (let key in grouped) {
        if (grouped.hasOwnProperty(key)) {
          let addToStore = true;
          let current = grouped[key];
          let obj = {
            reservationAk: current[0].reservationAk,
            firstName: current[0].firstName,
            lastName: current[0].lastName,
            sessionNames: current
              .map(a => {
                return a.surfName;
              })
              .join("<br />"),
            sessionTimes: current
              .map(a => {
                return moment
                  .utc(a.performanceDatetime)
                  .format("HH:mm");
              })
              .join(", "),
            age: moment().diff(
              moment(current[0].dateOfBirth, "DD/MM/YYYY"),
              "years"
            ),
            onSite:
							state.searchSurfWaiversActiveUser ===
							current[0].firstName,
            checkInLater: false,
            noShow: false,
            hasSignedWaiver: false,
            hasSeenVideo: false,
            promotionCode: current[0].promotionCode
          };

          for (let subKey in current) {
            let item = current[subKey];

            // Only show people who haven't already checked in
            if (item.checkins.length > 0) {
              addToStore = false;
            }

            //People can sign waivers externally before arriving on site
            if (item.hasSignedWaiver === true) {
              obj.hasSignedWaiver = true;
            }

            //People can seen video externally before arriving on site
            if (item.hasSeenVideo === true) {
              obj.hasSeenVideo = true;
            }
          }
          if (addToStore === true) {
            results.push(obj);
          }
        }
      }

      state.searchSurfWaiversResults = grouped;
      state.searchSurfWaivers = results;
    },
    SET_SEARCH_SURFERS_WAIVERS_INCLUDE_CHECKED_IN(state, payload) {
      const grouped = {};

      payload.forEach(item => {
        const key = item.firstName;
        const collection = key in grouped;
        if (!collection) {
          grouped[key] = [item];
        } else {
          grouped[key].push(item);
        }
      });

      let results = [];
      for (let key in grouped) {
        if (grouped.hasOwnProperty(key)) {
          let addToStore = true;
          let current = grouped[key];
          let obj = {
            reservationAk: current[0].reservationAk,
            firstName: current[0].firstName,
            lastName: current[0].lastName,
            sessionNames: current
              .map(a => {
                return a.surfName;
              })
              .join("<br />"),
            sessionTimes: current
              .map(a => {
                return moment
                  .utc(a.performanceDatetime)
                  .format("HH:mm");
              })
              .join(", "),
            age: moment().diff(
              moment(current[0].dateOfBirth, "DD/MM/YYYY"),
              "years"
            ),
            onSite:
							state.searchSurfWaiversActiveUser ===
							current[0].firstName,
            checkInLater: false,
            noShow: false,
            hasSignedWaiver: false,
            hasSeenVideo: false,
            promotionCode: current[0].promotionCode
          };

          for (let subKey in current) {
            let item = current[subKey];

            //People can sign waivers externally before arriving on site
            if (item.hasSignedWaiver === true) {
              obj.hasSignedWaiver = true;
            }

            //People can seen video externally before arriving on site
            if (item.hasSeenVideo === true) {
              obj.hasSeenVideo = true;
            }
          }
          if (addToStore === true) {
            results.push(obj);
          }
        }
      }

      state.searchSurfWaiversResults = grouped;
      state.searchSurfWaivers = results;
    },
    SET_SEARCH_SURFERS_WAIVERS_SUCCESS(state, payload) {
      state.searchSurfWaiversSuccess = payload;
    },
    SET_SEARCH_SURFERS_WAIVERS_ERROR(state, payload) {
      state.searchSurfWaiversError = payload;
    },
    SET_SEARCH_SURFERS_WAIVER_ACTIVE_USER(state, payload) {
      state.searchSurfWaiversActiveUser = payload;
    },
    RESET_SEARCH_SURFERS_WAIVERS(state) {
      state.searchSurfWaiversActiveUser = null;
      state.searchSurfWaiversError = null;
      state.searchSurfWaiversResults = [];
      state.searchSurfWaivers = [];
      state.searchSurfWaiversLoading = false;
    },
    ADD_PENDING_CHECKIN(state, payload) {
      state.pendingCheckIn.push(payload);
    }
  },
  actions: {
    fetchSurfers: async ({ commit }, payload) => {
      commit("SET_FETCHING_SURFERS_LOADING", true);
      await Vue.prototype.$surfLevelsAPI
        .fetchActiveSurfers(payload)
        .then(response => {
          if (response && response.success) {
            commit("SET_ACTIVE_SURFERS", response.data);
            commit("SET_FETCHING_SURFERS_SUCCESS", true);
            commit("SET_FETCHING_SURFERS_LOADING", false);
          } else {
            commit("SET_FETCHING_SURFERS_SUCCESS", false);
            commit("SET_FETCHING_SURFERS_LOADING", false);
          }
        });
    },
    fetchSurfersWaivers: async ({ commit }, payload) => {
      commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", true);
      await Vue.prototype.$surfWaiversAPI
        .fetchActiveSurfersWaivers(payload)
        .then(response => {
          if (response && response.success) {
            commit("SET_ACTIVE_SURFERS_WAIVERS", response.data);
            commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", false);
            commit("SET_FETCHING_SURFERS_WAIVERS_SUCCESS", true);
            commit(
              "SET_FETCHING_SURFERS_WAIVERS_TIMESTAMP",
              response.timestamp
            );
            var date = new Date();
            localStorage.setItem(
              "ACTIVE_SURFERS_WAIVERS_TIMESTAMP",
              JSON.stringify(date)
            );
            localStorage.setItem(
              "ACTIVE_SURFERS_WAIVERS",
              JSON.stringify(response.data)
            );
            localStorage.setItem(
              "SET_FETCHING_SURFERS_WAIVERS_SUCCESS",
              true
            );
          } else {
            commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", false);
            commit("SET_FETCHING_SURFERS_WAIVERS_SUCCESS", false);
          }
        });
    },
    updateSurfersWaiver: async ({ commit, dispatch }, payload) => {
      commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", true);
      await Vue.prototype.$restfulAPIService
        .updateResource("waivers", payload.uuid, payload)
        .then(response => {
          if (response && response.success) {
            commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", false);
            commit("SET_FETCHING_SURFERS_WAIVERS_SUCCESS", true);
            dispatch("fetchSurfersWaivers", {
              date: payload.activeDate
            });
          } else {
            commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", false);
            commit("SET_FETCHING_SURFERS_WAIVERS_SUCCESS", false);
          }
        });
    },
    staticUpdateSurfersWaiver: async ({ commit }, payload) => {
      commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", true);
      await Vue.prototype.$restfulAPIService
        .updateResource("waivers", payload.uuid, payload)
        .then(response => {
          if (response && response.success) {
            commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", false);
            commit("SET_FETCHING_SURFERS_WAIVERS_SUCCESS", true);
          } else {
            commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", false);
            commit("SET_FETCHING_SURFERS_WAIVERS_SUCCESS", false);
          }
        });
    },
    setSurfersWaivers: async ({ commit }, payload) => {
      commit("SET_ACTIVE_SURFERS_WAIVERS", payload);
    },
    createSurferCheckIn: async ({ commit, dispatch }, payload) => {
      commit("SET_CREATING_SURFERS_CHECKIN_LOADING", true);
      await Vue.prototype.$restfulAPIService
        .createResource("arrivals", payload)
        .then(response => {
          if (
            (response.status === 201 ||
							response.statusText === "Created") &&
						response.data
          ) {
            dispatch("fetchSurfersWaivers", {
              date: payload.activeDate
            });
            commit("SET_CREATING_SURFERS_CHECKIN_LOADING", false);
          }
        });
    },
    staticCreateSurferCheckIn: async ({ commit }, payload) => {
      commit("SET_CREATING_SURFERS_CHECKIN_LOADING", true);
      await Vue.prototype.$restfulAPIService
        .createResource("arrivals", payload)
        .then(response => {
          if (
            (response.status === 201 ||
							response.statusText === "Created") &&
						response.data
          ) {
            commit("SET_CREATING_SURFERS_CHECKIN_LOADING", false);
          }
        });
    },
    destroySurferCheckIn: async ({ commit, dispatch }, payload) => {
      commit("SET_CREATING_SURFERS_CHECKIN_LOADING", true);

      await Vue.prototype.$restfulAPIService
        .destroyResource("arrivals", payload.uuid)
        .then(response => {
          if (
            response.status === 204 ||
						response.statusText === "No Content"
          ) {
            dispatch("fetchSurfersWaivers", {
              date: payload.activeDate
            });
            commit("SET_CREATING_SURFERS_CHECKIN_LOADING", false);
          }
        });
    },
    // Useful For Local Storage Caching:
    fetchSurfersWaiversFromLocalStorage: async ({ commit }) => {
      var activeSurferWaivers = JSON.parse(
        localStorage.getItem("ACTIVE_SURFERS_WAIVERS")
      );
      commit("SET_ACTIVE_SURFERS_WAIVERS", activeSurferWaivers);
      commit("SET_FETCHING_SURFERS_WAIVERS_LOADING", false);
      commit("SET_FETCHING_SURFERS_WAIVERS_SUCCESS", true);
      localStorage.setItem(
        "ACTIVE_SURFERS_WAIVERS",
        JSON.stringify(activeSurferWaivers)
      );
    },
    searchSurfersWaivers: async ({ commit }, payload) => {
      commit("SET_SEARCH_SURFERS_WAIVERS_LOADING", true);

      let includeCheckedIn = false;

      if (payload.includeCheckedIn) {
        includeCheckedIn = true;
        delete payload.includeCheckedIn;
      }

      await Vue.prototype.$surfWaiversAPI
        .searchActiveSurfersWaivers(payload)
        .then(response => {
          if (response && response.success) {
            if (includeCheckedIn) {
              commit(
                "SET_SEARCH_SURFERS_WAIVERS_INCLUDE_CHECKED_IN",
                response.data
              );
            } else {
              commit("SET_SEARCH_SURFERS_WAIVERS", response.data);
            }
            commit("SET_SEARCH_SURFERS_WAIVERS_LOADING", false);
            commit("SET_SEARCH_SURFERS_WAIVERS_SUCCESS", true);
          } else {
            if (response.status === 404) {
              if ("last_name" in payload) {
                commit(
                  "SET_SEARCH_SURFERS_WAIVERS_ERROR",
                  `Sorry, we couldn't find any sessions for "${payload.last_name}" please check if that's correct, or ask a member of staff for help.`
                );
              } else {
                commit(
                  "SET_SEARCH_SURFERS_WAIVERS_ERROR",
                  "Sorry, an unknown error has occurred, if this happens again please ask a member of staff for help."
                );
              }
            } else {
              commit(
                "SET_SEARCH_SURFERS_WAIVERS_ERROR",
                "Sorry, an unknown error has occurred, if this happens again please ask a member of staff for help."
              );
            }
            commit("SET_SEARCH_SURFERS_WAIVERS_LOADING", false);
            commit("SET_SEARCH_SURFERS_WAIVERS_SUCCESS", false);
          }
        });
    },
    setActiveSurfWaiverUser: async ({ commit }, payload) => {
      commit("SET_SEARCH_SURFERS_WAIVER_ACTIVE_USER", payload);
    },
    resetSearchSurfWaivers: async ({ commit }) => {
      commit("RESET_SEARCH_SURFERS_WAIVERS");
    }
  }
};

const availability = {
  namespaced: true,
  state: {
    activeSurfAvailability: [],
    fetchingSurfAvailabilityLoading: false,
    fetchingSurfAvailabilitySuccess: false
  },
  getters: {
    activeSurfAvailability: state => {
      return state.activeSurfAvailability;
    }
  },
  mutations: {
    // API Request Mutations:
    SET_ACTIVE_SURF_AVAILABILITY(state, payload) {
      state.activeSurfAvailability = payload;
    },
    // API Response Mutations:
    SET_FETCHING_SURF_AVAILABILITY_SUCCESS(state, payload) {
      state.fetchingSurfAvailabilitySuccess = payload;
    },
    // Loading Mutations:
    SET_FETCHING_SURF_AVAILABILITY_LOADING(state, payload) {
      state.fetchingSurfAvailabilityLoading = payload;
    }
  },
  actions: {
    fetchSurfAvailability: async ({ commit }) => {
      commit("SET_FETCHING_SURF_AVAILABILITY_LOADING", true);
      await Vue.prototype.$restfulAPIService
        .fetchResource("availability")
        .then(response => {
          if (
            (response.status === 200 ||
							response.statusText === "OK") &&
						response.data
          ) {
            commit(
              "SET_ACTIVE_SURF_AVAILABILITY",
              response.data.data
            );
            commit("SET_FETCHING_SURF_AVAILABILITY_LOADING", false);
            commit("SET_FETCHING_SURF_AVAILABILITY_SUCCESS", true);

            var date = new Date();

            localStorage.setItem(
              "ACTIVE_SURF_AVAILABILITY_TIMESTAMP",
              JSON.stringify(date)
            );

            localStorage.setItem(
              "ACTIVE_SURF_AVAILABILITY",
              JSON.stringify(response.data.data)
            );

            localStorage.setItem(
              "SET_FETCHING_SURF_AVAILABILITY_SUCCESS",
              true
            );
          } else {
            commit("SET_FETCHING_SURF_AVAILABILITY_LOADING", false);
            commit("SET_FETCHING_SURF_AVAILABILITY_SUCCESS", false);
          }
        });
    },
    fetchSurfAvailabilityFromLocalStorage: async ({ commit }) => {
      var activeSurfAvailability = JSON.parse(
        localStorage.getItem("ACTIVE_SURF_AVAILABILITY")
      );
      commit("SET_ACTIVE_SURF_AVAILABILITY", activeSurfAvailability);
      commit("SET_FETCHING_SURF_AVAILABILITY_LOADING", false);
      commit("SET_FETCHING_SURF_AVAILABILITY_SUCCESS", true);
      localStorage.setItem(
        "ACTIVE_SURF_AVAILABILITY",
        JSON.stringify(activeSurfAvailability)
      );
    }
  }
};

export default new Vuex.Store({
  modules: {
    surfers: surfers,
    locations: locations,
    availability: availability,
    reservations: reservations,
    accounts: accounts
  }
});
