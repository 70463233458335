import axios from "axios";

/*
 * Create base axios instance for the Wave API:
 */

// const API_VERSION =
//   process.env.VUE_APP_WAVE_API_VERSION !== undefined
//     ? process.env.VUE_APP_WAVE_API_VERSION
//     : "1";

const axiosModified = axios.create({
	baseURL:
		process.env.VUE_APP_WAVE_API_BASE_URL !== undefined
			? `${process.env.VUE_APP_WAVE_API_BASE_URL}/v2`
			: ""
	// TODO: Consider moving the paramsSerializer from surfCancellationsAPIService.js to here
});

/*
 * Set default headers here:
 */
axiosModified.defaults.xsrfCookieName = "csrftoken";
axiosModified.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axiosModified.defaults.headers.common["Content-Type"] = "application/json";

export { axiosModified };
