import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routesWaivers = [
	// All Waivers: ✅ Tested V2 Migration:
	{
		path: "/waivers",
		name: "WaiversListView",
		component: () => import("@/views/Waivers.vue")
	},
	// Waiver Completion Rate
	{
		path: "/waivers/waivers-signed-count",
		name: "WaiversSignedCount",
		component: () => import("@/views/WaiversSignedCount.vue")
	},
	// Legacy Redirect:
	{
		path: "/surfers/waivers",
		redirect: {
			name: "WaiversListView"
		}
	},
	// Sign Waiver
	{
		path: "/surfers/sign-waivers",
		name: "SurferWaiver",
		component: () => import("@/views/waivers/SurferWaiver.vue")
	},
	{
		path: "/surfers/sign-waivers/select-surfer",
		name: "SurferWaiverSelect",
		component: () => import("@/views/waivers/SurferWaiverSelect.vue")
	},
	{
		path: "/surfers/sign-waivers/disclaimer",
		name: "SurferWaiverDisclaimer",
		component: () => import("@/views/waivers/SurferWaiverDisclaimer.vue")
	},
	{
		path: "/surfers/sign-waivers/video",
		name: "SurferWaiverVideo",
		component: () => import("@/views/waivers/SurferWaiverVideo.vue")
	},
	{
		path: "/surfers/sign-waivers/sign",
		name: "SurferWaiverSign",
		component: () => import("@/views/waivers/SurferWaiverSign.vue")
	},
	{
		path: "/surfers/sign-waivers/confirm",
		name: "SurferWaiverConfirm",
		component: () => import("@/views/waivers/SurferWaiverConfirm.vue")
	}
];

const routesAvailability = [
	// Availability: ✅ Tested V2 Migration:
	{
		path: "/availability",
		name: "Availability",
		component: () => import("@/views/Availability.vue")
	},
	// Legacy Redirect:
	{
		path: "/surf-sessions/availability",
		redirect: {
			name: "Availability"
		}
	},
	// Legacy Redirect:
	{
		path: "/surf-sessions/availability/landscape",
		redirect: {
			name: "Availability"
		}
	},
	// Legacy Redirect:
	{
		path: "/surf-sessions/availability/vertical",
		redirect: {
			name: "Availability"
		}
	}
];

// eslint-disable-next-line no-unused-vars
const routesCheckin = [
	{
		path: "/customer-check-in",
		name: "CheckIn",
		component: () => import("@/views/CustomerCheckIn.vue")
	},
	{
		path: "/customer-check-in/:lastName/:reservationAk",
		name: "CheckIn2",
		component: () => import("@/views/CustomerCheckIn-Step2.vue")
	},
	{
		path: "/customer-check-in/:lastName/:reservationAk/confirm",
		name: "CheckInConfirm",
		component: () => import("@/views/CustomerCheckIn-Confirm.vue")
	},
	{
		path: "/customer-check-in/:lastName/:reservationAk/terms",
		name: "CheckInTerms",
		component: () => import("@/views/CustomerCheckIn-Terms.vue")
	},
	{
		path: "/customer-check-in/:lastName/:reservationAk/complete",
		name: "CheckInComplete",
		component: () => import("@/views/CustomerCheckIn-Complete.vue")
	},
	{
		path: "/surf-ops/customer-check-in",
		name: "SurfOps/CheckIn",
		component: () =>
			import("@/views/surf-ops/customer-check-in/CustomerCheckIn.vue")
	},
	{
		path: "/surf-ops/customer-check-in/:reservationAk",
		name: "SurfOps/CheckIn2",
		component: () =>
			import(
				"@/views/surf-ops/customer-check-in/CustomerCheckIn-Step2.vue"
			)
	},
	{
		path: "/surf-ops/customer-check-in/:reservationAk/confirm",
		name: "SurfOps/CheckInConfirm",
		component: () =>
			import(
				"@/views/surf-ops/customer-check-in/CustomerCheckIn-Confirm.vue"
			)
	},
	{
		path: "/surf-ops/customer-check-in/:reservationAk/terms",
		name: "SurfOps/CheckInTerms",
		component: () =>
			import(
				"@/views/surf-ops/customer-check-in/CustomerCheckIn-Terms.vue"
			)
	},
	{
		path: "/surf-ops/customer-check-in/:reservationAk/video",
		name: "SurfOps/CheckInVideo",
		component: () =>
			import(
				"@/views/surf-ops/customer-check-in/CustomerCheckIn-Video.vue"
			)
	},
	{
		path: "/surf-ops/customer-check-in/:reservationAk/complete",
		name: "SurfOps/CheckInComplete",
		component: () =>
			import(
				"@/views/surf-ops/customer-check-in/CustomerCheckIn-Complete.vue"
			)
	}
];

const routesCancellations = [
	{
		path: "/cancellations",
		name: "Cancellations",
		component: () => 
			import("@/views/Cancellations.vue")
	}
]

const routesSurfOps = [
	{
		path: "/surf-ops",
		name: "SurfOps",
		component: () => 
			import("@/views/SurfOps.vue")
	},
];

const routes = [].concat(routesWaivers, routesAvailability, routesCancellations, routesSurfOps);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
