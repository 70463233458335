// Thanks To https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
async function asyncForEach(array, asyncCallback) {
	for (let x = 0; x < array.length; x++) {
		await asyncCallback(array[x]);
	}
}

export default {
	install(Vue) {
		Vue.prototype.$asyncForEach = asyncForEach;
	}
};
