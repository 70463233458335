/* eslint-disable no-console */
import { axiosModified } from "@/services/base";
import { EventEmitter } from "events";

class SurfWaiversServiceAPI extends EventEmitter {
	constructor() {
		super();
		this.accessToken = "";
	}

	fetchActiveSurfersWaivers(payload) {
		return new Promise(resolve => {
			axiosModified
				.get(`/waivers`, {
					headers: {},
					params: payload
				})
				.then(response => {
					if (response.success) {
						resolve(response.data);
					} else {
						resolve(response.data);
					}
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}

	updateActiveSurferWaiver(payload) {
		// The Payload Here Represents SurferWaiver JSON:
		let data = payload;

		// Handle the payload.surferWaiver being nested and bring it up a level
		if (payload.surferWaiver) {
			data = payload.surferWaiver;
		}

		return new Promise(resolve => {
			axiosModified
				.put(`/waivers/`, data, { headers: {} })
				.then(response => {
					if (response.success) {
						resolve(response.data);
					} else {
						resolve(response.data);
					}
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}

	createActiveSurferCheckIn(payload) {
		return new Promise(resolve => {
			axiosModified
				.post(
					`/waivers/surf-checkins/`,
					{
						surfWaiver: payload.surferWaiverUUID,
						location: payload.surfSiteLocationUUID
					},
					{ headers: {} }
				)
				.then(response => {
					if (response.success) {
						resolve(response.data);
					} else {
						resolve(response.data);
					}
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}

	destroyActiveSurferCheckIn(payload) {
		return new Promise(resolve => {
			axiosModified
				.delete(`/waivers/surf-checkins/${payload.surfCheckInUUID}`, {
					headers: {}
				})
				.then(response => {
					if (response.success) {
						resolve(response.data);
					} else {
						resolve(response.data);
					}
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}

	searchActiveSurfersWaivers(payload) {
		let url = "/waivers";
		let count = 0;
		for (let key in payload) {
			if (payload.hasOwnProperty(key)) {
				let value = payload[key];
				value = value.toLowerCase();
				url += count === 0 ? `?${key}=${value}` : `&${key}=${value}`;
				count++;
			}
		}
		return new Promise(resolve => {
			axiosModified
				.get(url, { headers: {} })
				.then(response => {
					if (response.success) {
						resolve(response.data);
					} else {
						resolve(response.data);
					}
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}
}

const surfWaiversServiceAPI = new SurfWaiversServiceAPI();

surfWaiversServiceAPI.setMaxListeners(5);

export default surfWaiversServiceAPI;
