import { axiosModified } from "@/services/base";
import { EventEmitter } from "events";

class GetWeatherForecast extends EventEmitter {
	constructor() {
		super();
		this.accessToken = "";
	}

	getForecast(params) {
		return new Promise(resolve => {
			axiosModified
				.get(`https://api.openweathermap.org/data/2.5/forecast?`, {
					headers: {},
					params
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}

	getLakeTemperature() {
		return new Promise(resolve => {
			axiosModified
				.get(`https://wave-sensors.web.app/api/lakeTemp`)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}
}

const getWeatherForecast = new GetWeatherForecast();

getWeatherForecast.setMaxListeners(5);

export default getWeatherForecast;
