import axios from "axios";

/*
 * Create base axios instance for the Wave API:
 */

// const API_VERSION =
//   process.env.VUE_APP_WAVE_API_VERSION !== undefined
//     ? process.env.VUE_APP_WAVE_API_VERSION
//     : "1";

const axiosWave = axios.create({
	baseURL:
		process.env.VUE_APP_WAVE_API_BASE_URL !== undefined
			? `${process.env.VUE_APP_WAVE_API_BASE_URL}/wave/api`
			: ""
});

/*
 * Set default headers here:
 */
axiosWave.defaults.xsrfCookieName = "csrftoken";
axiosWave.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axiosWave.defaults.headers.common["Content-Type"] = "application/json";

export { axiosWave };
