/* eslint-disable no-console */
import { axiosWave } from "@/services/http";
import { EventEmitter } from "events";

class SurfSitesServiceAPI extends EventEmitter {
	constructor() {
		super();
		this.accessToken = "";
	}

	fetchActiveSurfSiteLocations() {
		return new Promise(resolve => {
			axiosWave
				.get(`/v2/locations`, { headers: {} })
				.then(response => {
					if (response.success) {
						resolve(response.data);
					} else {
						resolve(response.data);
					}
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}
}

const surfSitesServiceAPI = new SurfSitesServiceAPI();

surfSitesServiceAPI.setMaxListeners(5);

export default surfSitesServiceAPI;
