import Vue from "vue";
import VueMoment from "vue-moment";

import App from "./App.vue";

// import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "@/assets/css/tailwind.css";

Vue.config.productionTip = false;

import WaveSurfSitesAPIPlugin from "@/plugins/surfSitesAPI";
import WaveSurfWaiversAPIPlugin from "@/plugins/surfWaiversAPI";
import AccountsAPIPlugin from "@/plugins/accountsAPI";
import ArrayHelpers from "@/plugins/arrayHelpers";
import surfCancellations from "@/plugins/surfRefunds";
import surfEvents from "@/plugins/surfEvents";
import surfCalendarAvailability from "@/plugins/surfCalendarAvailability"
import getWeatherAPI from "@/plugins/getWeatherAPI";

import restfulAPIService from "@/plugins/restfulAPIService";

// Wave Specific Plugins
Vue.use(WaveSurfSitesAPIPlugin);
Vue.use(WaveSurfWaiversAPIPlugin);
Vue.use(AccountsAPIPlugin);
Vue.use(ArrayHelpers);
Vue.use(surfCancellations);
Vue.use(surfEvents);
Vue.use(surfCalendarAvailability);
Vue.use(getWeatherAPI);

Vue.use(restfulAPIService);

// Vue-Moment Third Party Library
Vue.use(VueMoment);

// Date FNS:

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
