/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";

import * as moment from "moment";

Vue.use(Vuex);

class ReservationCollation {
	success = false;

	primaryEmailAddress = "";
	selectedFirstName = "";

	accounts = [];

	waivers = [];
	waiversRaw = [];
	waiversAdults = [];
	waiversMinors = [];

	fetchDateTime = new Date();
	fetchApi = false;
	fetchLocal = false;

	excluded_surf_codes = [
		'SPECTATOR',
		'Spectator Admission',
		'Under 5 SPEC'
	];

	constructor(
		passedPrimaryEmailAddress = "",
		passedAccounts = [],
		passedFetchDateTime = new Date(),
		fetchType = null
	) {
		this.accounts = passedAccounts;
		this.primaryEmailAddress = passedPrimaryEmailAddress;
		this.fetchDateTime = passedFetchDateTime;

		this.splitAccounts();
		this.splitWaivers();

		fetchType === "local"
			? (this.fetchLocal = true)
			: (this.fetchLocal = false);
		fetchType === "api" ? (this.fetchApi = true) : (this.fetchApi = false);

		this.waivers.length > 0 ? (this.success = true) : (this.success = false);

		this.parseAdults();
	}

	splitAccounts() {
		this.accounts.forEach(account => {
			account.waivers.map(waiverRaw => {
				// Check it's not an Excluded Surf before adding
				// So they don't have a video to watch nor a waiver to sign
				// Adding if their video is not "none" or their waiver is not false
				if(
					(waiverRaw.event && waiverRaw.event.videoRequired != "none") ||
					(waiverRaw.event && waiverRaw.event.waiverRequired)
				){
					// Mixin Requirements
					let requirements = {
						videoRequiredBay: false,
						videoRequiredReef: false,
						waiverRequired: false
					};
					waiverRaw.requirements = this.updateRequirements(requirements, waiverRaw);

					// Mixin Last Sign/Watch
					/*
					waiverRaw.account = {
						"uuid": account.uuid,
						"videoReefLastWatched": account.videoReefLastWatched,
						"videoBayLastWatched": account.videoBayLastWatched,
						"waiverLastSigned": account.waiverLastSigned
					}
					*/

					// Add to original waivers raw array	
					this.waiversRaw.push(waiverRaw);
				}
			});
		});
	}

	splitWaivers() {
		// Unique Identifiers
		const uniqueIdentifiers = [];

		// Add to Storages
		this.waiversRaw.map(waiver => {
			// Unique Identifier Var
			let uniqueIdentifier = waiver.firstName.toLowerCase() + '_' + waiver.lastName.toLowerCase()

			// Make Booking Object
			let booking = {
				performanceDatetime: waiver.performanceDatetime,
				surfName: waiver.surfName
			};

			// Check for Duplicates
			if (
				!uniqueIdentifiers.includes(uniqueIdentifier)
			) {
				// Add Fresh Record
				this.waivers.push({
					...waiver,
					uniqueIdentifier: uniqueIdentifier,
					bookings: [booking]
				});

				// Fill In Uniques
				uniqueIdentifiers.push(uniqueIdentifier);
			}
			else {
				// Get Stored Waiver
				let storedWaiver = this.waivers.filter(
					waiver => waiver.uniqueIdentifier == uniqueIdentifier
				)[0];

				// Refresh Requirements (inherit the Previous Waiver(s) Requirements)
				storedWaiver.requirements = this.updateRequirements(storedWaiver.requirements, waiver);

				// Add Booking
				storedWaiver.bookings.push(booking);
			}
		});
	}

	updateRequirements(requirements, waiver){
		// Event Amend Requirements
		if(!waiver.event) {
			requirements.videoRequiredBay = true;
			requirements.videoRequiredReef = true;
			requirements.waiverRequired = true;
		}
		else {
			if(waiver.event.waiverRequired)
				requirements.waiverRequired = true;

			switch(waiver.event.videoRequired){
				case "reef":
					requirements.videoRequiredReef = true;
					break;

				case "bay":
					requirements.videoRequiredBay = true;
					break;
			}
		}

		return(requirements);
	}

	parseAdults() {
        this.waivers.map(waiver => { 
			if(!this.isAdult(waiver.dateOfBirth)) this.waiversMinors.push(waiver);
			else this.waiversAdults.push(waiver);
		});
	}

	isAdult(dob) {
		if (moment().diff(moment(dob, "DD/MM/YYYY"), "years") >= 18)
			return true;
		return false;
	}

	dataExpire() {
		// Reset Local Store
		window.localStorage.removeItem("SURFER_RESERVATION");

		// Reset VueX Store
		this.success = false;
	}

	setWaiverFirstName(passedFirstName){
		this.selectedFirstName = passedFirstName;
	}

	getWaiverFirstName() {
		const waiverFound = this.waiversAdults.filter(
			waiver => waiver.firstName.toLowerCase() == this.selectedFirstName.toLowerCase()
		);

		if(waiverFound.length > 0)
			return [waiverFound[0]];
		return [];
	}

	getWaiversSignable() {
		// Local Filtered Arrays
		const waiversMinors = this.waiversMinors.filter(
			waiver => 
				waiver.hasSeenVideo != true || 
				waiver.hasSignedWaiver != true
		);

		if(this.selectedFirstName !== ""){
			var waiverSelectedAdult = this.getWaiverFirstName().filter(
				waiver => 
					waiver.hasSeenVideo != true || 
					waiver.hasSignedWaiver != true
			);

			return (
				waiversMinors.concat(
					waiverSelectedAdult
				)
			);
		}

		const waiversAdults = this.waiversAdults.filter(
			waiver => 
				waiver.hasSeenVideo != true || 
				waiver.hasSignedWaiver != true
		);

		if(waiversAdults.length > 0)
			return (
				waiversMinors.concat(
					[waiversAdults[0]]
				)
			);
		
		return (waiversMinors);
	}

	getWaiversSignableRaw() {
		// Local Consts
		const waiversRawMinors = [];
		const waiversRawAdults = [];
		let waiverRawSelected = [];

		// Seperate Raw Adults and Raw Minors
		this.waiversRaw.map(waiver => { 
			if(!this.isAdult(waiver.dateOfBirth)) waiversRawMinors.push(waiver);
			else waiversRawAdults.push(waiver);
		});

		// Local Filtered Arrays
		const waiversMinors = waiversRawMinors.filter(
			waiver => 
				waiver.hasSeenVideo != true || 
				waiver.hasSignedWaiver != true
		);

		const waiversAdults = waiversRawAdults.filter(
			waiver => 
				waiver.hasSeenVideo != true || 
				waiver.hasSignedWaiver != true
		);

		// Find Raw Adult from First Name
		if(this.selectedFirstName !== ""){
			const waiverRawFound = waiversAdults.filter(
				waiver => waiver.firstName.toLowerCase() == this.selectedFirstName.toLowerCase()
			);

			return (
				waiversMinors.concat(
					waiverRawFound
				)
			);
		}

		// Else If Return First Adult
		if(waiversAdults.length > 0)
			return (
				waiversMinors.concat(
					waiversAdults
				)
			);

		// Else Return Minors
		return (
			waiversMinors
		);
	}

	toJson() {
		return {
			success: this.success,
			primaryEmailAddress: this.primaryEmailAddress,

			accounts: this.accounts,

			waivers: this.waivers,
			waiversRaw: this.waiversRaw,
			waiversAdults: this.waiversAdults,
			waiversMinors: this.waiversMinors,

			fetchDateTime: this.fetchDateTime
		};
	}
}

const reservations = {
	namespaced: true,
	state: {
		currentCollation: {}
	},
	mutations: {
		setReservation(state, payload) {
			state.currentCollation = payload;
		}
	},
	getters: {
		findReservation: state => {
			return state.currentCollation;
		}
	},
	actions: {
		clearReservation({ commit }) {
			var surferReservation = undefined;

			surferReservation = window.localStorage.getItem(
				"SURFER_RESERVATION"
			);
			if (surferReservation !== undefined) {
				// Clear Reservation
				window.localStorage.removeItem(
					"SURFER_RESERVATION"
				);

				// Blank Reservation
				var reservation = new ReservationCollation();

				// VueX Store Response
				commit("setReservation", reservation);
			}
		},
		async fetchReservation({ dispatch, getters }, primaryEmailAddress) {
			var foundReservation = undefined;

			// Check ReservationAk
			if (primaryEmailAddress !== undefined && primaryEmailAddress.length > 0) {
				// If in VueX return it
				foundReservation = getters["findReservation"];
				if (foundReservation !== undefined && foundReservation.success) {
					return foundReservation;
				}

				// Then load Local Storage
				dispatch("fetchReservationLocal", primaryEmailAddress);

				// If from Local Storage return it
				foundReservation = getters["findReservation"];
				if (foundReservation !== undefined && foundReservation.success) {
					return foundReservation;
				}

				// Then load Fetcher
				await dispatch("fetchReservationApi", primaryEmailAddress);

				// If from Fetcher return it
				foundReservation = getters["findReservation"];
				if (foundReservation !== undefined && foundReservation.success) {
					return foundReservation;
				}
			}
			// Finally Return Failure
			return new ReservationCollation();
		},
		fetchReservationLocal({ commit }, primaryEmailAddress) {
			var surferReservation = undefined;

			surferReservation = window.localStorage.getItem(
				"SURFER_RESERVATION"
			);
			if (surferReservation !== undefined) {
				// JSON Reservation
				surferReservation = JSON.parse(surferReservation);

				// Check & Parse Reservation
				if (surferReservation && surferReservation.success) {
					var reservation = new ReservationCollation(
						primaryEmailAddress,
						surferReservation.accounts,
						surferReservation.fetchDateTime,
						"local"
					);

					// VueX Store Response
					commit("setReservation", reservation);
				}
			}
		},
		async fetchReservationApi({ dispatch, commit }, primaryEmailAddress) {
			// Clear out previous accounts to start with
			dispatch("accounts/clearAccounts", null, { root: true });

			// Get the Stored, or API-Fresh, Accounts
			var accounts = await dispatch("accounts/getAccountsByEmailAddress", primaryEmailAddress, { root: true});

			// Submit Reservation
			var reservation = new ReservationCollation(
				primaryEmailAddress,
				accounts,
				new Date(),
				"api"
			);

			// Local Store Response
			window.localStorage.setItem(
				"SURFER_RESERVATION",
				JSON.stringify(reservation.toJson())
			);

			// VueX Store Response
			commit("setReservation", reservation);
		}
	}
};

export default reservations;
