import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const locations = {
	namespaced: true,
	state: {
		activeSurfSiteId: process.env.VUE_APP_WAVE_SURF_SITE_ID,
		activeSurfSiteLocations: []
	},
	getters: {
		activeSurfSiteLocations: state => {
			return state.activeSurfSiteLocations;
		}
	},
	mutations: {
		// API Request Mutations:
		SET_ACTIVE_LOCATIONS(state, payload) {
			state.activeSurfSiteLocations = payload;
		}
	},
	actions: {
		fetchSurfSiteLocations: async ({ commit }) => {
			await Vue.prototype.$restfulAPIService
				.fetchResource("locations")
				.then(response => {
					if (
						(response.status === 200 ||
							response.statusText === "OK") &&
						response.data
					) {
						commit("SET_ACTIVE_LOCATIONS", response.data);
					}
				});
		}
	}
};

export default locations;
