<template>
	<div class="bg-gray-200">
		<div id="app">
			<router-view />
		</div>
		<!-- <WaveFooter /> -->
	</div>
</template>

<script>
	/* eslint max-len: ["error", { "ignoreUrls": true }] */
	/* eslint max-len: ["error", { "ignoreComments": true }] */
	/* eslint max-len: ["error", { "ignoreStrings": true }] */
	/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
	/* eslint max-len: ["error", { "code": 200 }] */
	/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["bar"] }] */

	// @ is an alias to /src
	// import WaveFooter from "@/components/WaveFooter.vue";

	export default {
		name: "WaveApp",
		components: {
			// Initialise components here:
			// WaveFooter,
		}
	};
</script>

<style lang="scss">
	$wave: #23423a !default;

	html,
	body {
		font-family: Poppins, serif !important;
		font-display: swap;
		font-weight: 500;

		max-width: 100vw;
		overflow-x: hidden;
	}

	body {
		// background-image: url(https://media.thewave.com/images/homepage-birds-eye-view-deep-blue-ocean-the-w.width-1000.jpg);
		// background-position: top;
		// background-repeat: no-repeat;
		// background-size: cover;
	}

	*,
	::after,
	::before {
		box-sizing: border-box;
		font-stretch: 100%;
	}

	::selection {
		background: #003d4c;
		color: #fff;
	}

	select {
		font-family: Poppins;
	}

	td {
		height: 3rem;
	}

	a {
		display: block;
		position: relative;

		&::after {
			transition: all 0.15s cubic-bezier(0.47, 0, 0.745, 0.715);
			content: "";
			position: absolute;
			left: 0;
			bottom: -1px;
			height: 0;
			width: 100%;
			background: #3fcfd5;
		}

		&:hover {
			&::after {
				height: 2px;
			}
		}
	}

	#app {
		min-height: 100vh;
		z-index: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
</style>
