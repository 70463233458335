/** eslint-disable no-console */
import { axiosModified } from "@/services/base";
import { EventEmitter } from "events";


class surfCancellations extends EventEmitter{
	constructor(){
		super();
		this.accessToken = "";
	}

	/**
	 * Method to fetch all of the users that got a refund
	 * 
	 * We need to fetch this from a different api then return it so we can
	 * convert it to a csv in 
	 */
	fetchSurfRefunds(params, payload = {}){
		return new Promise(resolve => {
			axiosModified
				.post(
					`/waivers/cancellations/`, 
					payload, 
					{
						params,
						paramsSerializer: (params) => {
							let result = "";
							for (const [key, value] of Object.entries(params)) {
								// Handle array parameters
								if(Array.isArray(value))
									params[key] = value.join(`&${key}=`);

								// Store query
								result = result + `${key}=${params[key]}&`;
							}
							return result;
						},
						headers: {}
					}
				)
				.then(response => 
					resolve(response.data)
				)
				.catch(error => 
					resolve(error.response)
				);
		});
	}
}

const surfCancellationsNew = new surfCancellations();

surfCancellationsNew.setMaxListeners(5);

export default surfCancellationsNew;