import { axiosModified } from "@/services/base";
import { EventEmitter } from "events";

class SurfCalendarAvailability extends EventEmitter {
	constructor() {
		super();
		this.accessToken = "";
	}

	//method to list ALL of the surf events that can take place
	fetchCalendarAvailability(params) {
		return new Promise(resolve => {
			axiosModified
				.get(`/availability-calendar/`, {
					headers: {},
					params
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					resolve(error.response);
				});
		});
	}
}

const surfCalendarAvailability = new SurfCalendarAvailability();

surfCalendarAvailability.setMaxListeners(5);

export default surfCalendarAvailability;