/* eslint-disable no-console */
import { axiosModified } from "@/services/base";
import { EventEmitter } from "events";

class AccountsAPIService extends EventEmitter {
	constructor() {
		super();
		this.accessToken = "";
	}

	fetchAccount(payload) {
		return new Promise(resolve => {
			axiosModified
				.get(`/accounts/${payload}`, {
					headers: {}
				})
				.then(response => resolve(response.data))
				.catch(error => resolve(error.response));
		});
	}

	fetchAccounts(payload) {
		return new Promise(resolve => {
			axiosModified
				.get(`/accounts`, {
					headers: {},
					params: payload
				})
				.then(response => resolve(response.data))
				.catch(error => resolve(error.response));
		});
	}

	updateAccountPartial(payload) {
		if (payload.uuid)
			return new Promise(resolve => {
				axiosModified
					.patch(`/accounts/${payload.uuid}/`, payload, {
						headers: {}
					})
					.then(response => resolve(response.data))
					.catch(error => resolve(error.response));
			});
		else console.log("Incorrect Payload", payload);
	}

	updateAccount(payload) {
		if (payload.uuid)
			return new Promise(resolve => {
				axiosModified
					.put(`/accounts/${payload.uuid}/`, payload, {
						headers: {}
					})
					.then(response => resolve(response.data))
					.catch(error => resolve(error.response));
			});
		else console.log("Incorrect Payload", payload);
	}
}

// Setup and Export a new class instance
const accountsAPIService = new AccountsAPIService();

accountsAPIService.setMaxListeners(5);

export default accountsAPIService;
